import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { message, Input, Button } from 'antd'; // Import Ant Design components
import './App.css';
import backgroundVideo from './assets/background.mp4';

const App = () => {
    const [visiblePrompt, setVisiblePrompt] = useState('');
    const [phase, setPhase] = useState('typing');
    const [remainingPrompts, setRemainingPrompts] = useState([]);
    const [email, setEmail] = useState(''); // State to track email input

    const prompts = [
        "How's the traffic on my route?",
        "Summarize today's tasks.",
        "What's the ETA for delivery?",
        "Check raw material levels and reorder if needed.",
        "Suggest optimizations for my fleet.",
        "Book a flight to New York tomorrow.",
        "Track the status of my latest purchase order",
        "Find the best time for a trip next month.",
        "Track my fitness goals",
        "Monitor live flight statuses for my upcoming trip",
        "Check my cloud storage and clean up old files",
        "Draft a response for the latest client inquiry in my inbox",
        "Alert me about high-priority tasks in Asana for today",
        "What's the current stock level of our best-selling products?",
        "Set a timer for 20 minutes and play my favorite playlist",
        "Create a grocery list based on my meal plans for the week"
    ];

    const headerAnimation = useSpring({ from: { opacity: 0, y: 30 }, to: { opacity: 1, y: 0 }, delay: 300 });
    const contentAnimation = useSpring({ from: { opacity: 0, y: 50 }, to: { opacity: 1, y: 0 }, delay: 600 });

    // Initialize the shuffled prompts list
    useEffect(() => {
        setRemainingPrompts(shuffleArray([...prompts]));
    }, []);

    // Utility function to shuffle the prompts array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    useEffect(() => {
        let timeout;
        const currentPrompt = remainingPrompts[0] || '';

        if (phase === 'typing') {
            timeout = setTimeout(() => {
                setVisiblePrompt(currentPrompt.slice(0, visiblePrompt.length + 1));
            }, 100);
            if (visiblePrompt === currentPrompt) setPhase('holding');
        } else if (phase === 'holding') {
            timeout = setTimeout(() => setPhase('deleting'), 1000);
        } else if (phase === 'deleting') {
            timeout = setTimeout(() => {
                setVisiblePrompt((prev) => prev.slice(0, -1));
                if (visiblePrompt === '') {
                    setPhase('typing');
                    setRemainingPrompts((prev) => {
                        const updatedPrompts = [...prev.slice(1)];
                        if (updatedPrompts.length === 0) {
                            return shuffleArray(prompts); // Reshuffle when all prompts are used
                        }
                        return updatedPrompts;
                    });
                }
            }, 50);
        }

        return () => clearTimeout(timeout);
    }, [visiblePrompt, phase, remainingPrompts]);

    const handleEmailSubmit = async () => {
        const isValidEmail = (email) =>
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (!isValidEmail(email)) {
            message.error('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('/api/v1/emails/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    recipient_email: email,
                    status: 'pending',
                }),
            });

            if (response.ok) {
                message.success('Thank you for joining the waitlist!');
                setEmail('');
            } else {
                message.error('Failed to join the waitlist. Please try again.');
            }
        } catch (error) {
            message.error('Error connecting to the server. Please try again later.');
        }
    };

    return (
        <div className="app">
            <video
                autoPlay
                loop
                muted
                playsInline
                className="background-video"
            >
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="content">
                <animated.div style={headerAnimation}>
                    <h1 className="header">Welcome to <span className="highlight">SiDEX</span></h1>
                    <p className="tagline">Bridge the gap between technology and operations.</p>
                </animated.div>
                <animated.div style={contentAnimation} className="prompt-container">
                    <div className="modern-box">
                        <div className='text-box'>
                            <span>{visiblePrompt}<span className="cursor">|</span></span>
                        </div>
                        <i className="fas fa-paper-plane send-icon"></i>
                    </div>
                </animated.div>
                <animated.div style={contentAnimation} className="contact-section">
                    <div className="contact-form">
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            value={email}

                            onChange={(e) => setEmail(e.target.value)}
                            className="custom-input" // Apply custom styles
                        />
                        <button onClick={handleEmailSubmit} class="btn-hover color-1">JOIN WAITLIST</button>
                    </div>
                </animated.div>
            </div>
        </div>
    );
};

export default App;